export function createIndexDictionary<T extends string>(
  arr: ReadonlyArray<T>
): Partial<Record<T, number>> {
  const result: Partial<Record<T, number>> = {};
  arr.forEach((v, i) => (result[v] = i));
  return result;
}

export const createIndexDictionaryAssumeFull = createIndexDictionary as <
  T extends string
>(
  arr: ReadonlyArray<T>
) => Readonly<Record<T, number>>;

export function cx(
  ...args: ReadonlyArray<
    string | null | undefined | Record<string, boolean | undefined>
  >
) {
  const results: string[] = [];
  for (const arg of args) {
    if (typeof arg === "string") {
      results.push(arg);
    } else if (arg !== null && arg !== undefined) {
      for (const entry in arg) {
        if (arg[entry]) {
          results.push(entry);
        }
      }
    }
  }
  return results.join(" ");
}

export function readonlyRecordOf<V>() {
  return <T extends Record<any, V>>(obj: T): Readonly<Record<keyof T, V>> => {
    return obj;
  };
}

export function recordKeys<K extends string | number>(obj: Record<K, any>) {
  return Object.keys(obj) as K[] as ReadonlyArray<K>;
}
