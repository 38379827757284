import { Mode } from "./QueryParameterHelpers";

const ASPECT_RATIO = 0.714;

const CARD_SPACE_PX = 10;
const MAX_HEIGHT_PCT = 0.8;
const FOOTER_HEIGHT_PX = 15;
const EXTRA_HEIGHT_PX: Partial<Record<Mode, number>> = {
  dealer: 40
};

function px(n: number) {
  return `${n}px`;
}

export function determineCardSize(mode: Mode) {
  const numCardsInRow = mode === "dealer" ? 5 : 4;
  const numSpaces = numCardsInRow + 1; // between each + on either side

  let cardWidthPx =
    (window.innerWidth - numSpaces * CARD_SPACE_PX) / numCardsInRow;
  let cardHeightPx = cardWidthPx / ASPECT_RATIO;

  const maxHeight = Math.max(
    0,
    (window.innerHeight - FOOTER_HEIGHT_PX - (EXTRA_HEIGHT_PX[mode] ?? 0)) *
      MAX_HEIGHT_PCT
  );

  if (cardHeightPx > maxHeight) {
    cardHeightPx = maxHeight;
    cardWidthPx = cardHeightPx * ASPECT_RATIO;
  }

  return { cardHeight: px(cardHeightPx), cardWidth: px(cardWidthPx) };
}

export const cardGapSize = px(CARD_SPACE_PX);
