import { memoize } from "lodash";
import Pusher from "pusher-js";
import { Mode } from "../helpers/QueryParameterHelpers";
import { pusherConfig } from "./PusherConfig";

export const getOrCreatePusher = memoize(
  (mode: Mode) =>
    new Pusher(pusherConfig.key, {
      cluster: pusherConfig.cluster,
      auth: {
        params: {
          username: `${mode}:${Date.now().toString(36)}.${Math.floor(
            Math.random() * 4096
          ).toString(36)}`
        }
      }
    })
);
