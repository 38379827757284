import React from "react";
import { Card } from "./cards/Cards";
import CardRowView from "./ui/CardRowView";

interface State {
  handId?: string;
  holeCards?: readonly Card[];
}

export default class PlayerView extends React.Component<{}, State> {
  public state: State = {};

  public render() {
    const { handId, holeCards } = this.state;
    if (handId === undefined) {
      return <div>Waiting for next hand...</div>;
    }

    return (
      <CardRowView cards={holeCards ?? []} numCards={holeCards?.length ?? 2} />
    );
  }

  public startNewHand(handId: string, holeCards: readonly Card[]) {
    this.setState({ handId, holeCards: [...holeCards] });
  }

  public reset() {
    this.setState({ handId: undefined, holeCards: undefined });
  }
}
