import React, { Fragment } from "react";
import { allCards, Card } from "./cards/Cards";
import Deck from "./cards/Deck";
import { GameType, getGameTypeConfig } from "./GameType";
import Button from "./ui/Button";
import CardRowView from "./ui/CardRowView";

interface Props {
  getPlayerNames: () => string[];
  sendNewHandData: (
    handId: string,
    playersAndCards: Record<string, Card[]>
  ) => void;
  gameType: GameType;
}

interface State {
  deck?: Deck;
  communityCards: readonly Card[];
}

export default class DealerView extends React.Component<Props, State> {
  public state: State = { communityCards: [] };

  public render() {
    const { deck, communityCards } = this.state;
    const isActiveHand = deck !== undefined;
    let nextStreet: string | null = null;
    if (isActiveHand) {
      switch (communityCards.length) {
        case 0:
          nextStreet = "Flop";
          break;

        case 3:
          nextStreet = "Turn";
          break;

        case 4:
          nextStreet = "River";
          break;
      }
    }

    const dealNextIsLarge = nextStreet !== null;
    const dealNewIsLarge = nextStreet === null;

    return (
      <div>
        <CardRowView cards={communityCards} numCards={5} />
        <br />
        {nextStreet === null ? null : (
          <Fragment>
            <Button onClick={this.dealNextStreet} large={dealNextIsLarge}>
              Show {nextStreet}
            </Button>{" "}
          </Fragment>
        )}
        <Button onClick={this.dealNewHand} large={dealNewIsLarge}>
          Deal {isActiveHand ? "New " : ""}Hand
        </Button>
      </div>
    );
  }

  private readonly dealNewHand = () => {
    const handId = Math.floor(Math.random() * 65536).toString(16);
    const deck = new Deck(allCards);
    deck.shuffle();

    const players = this.props.getPlayerNames();
    const playersAndCards: Record<string, Card[]> = {};
    const numCards = getGameTypeConfig(this.props.gameType).numHoleCards;
    for (const player of players) {
      const cards: Card[] = [];
      for (let i = 0; i < numCards; ++i) {
        cards.push(deck.draw());
      }
      playersAndCards[player] = cards;
    }

    this.setState({ deck, communityCards: [] });
    this.props.sendNewHandData(handId, playersAndCards);
  };

  private readonly dealNextStreet = () => {
    const { communityCards, deck } = this.state;
    if (deck === undefined) {
      return;
    }

    const numToShow = communityCards.length === 0 ? 3 : 1;
    const newCommunityCards = [...communityCards];
    for (let i = 0; i < numToShow; ++i) {
      newCommunityCards.push(deck.draw());
    }
    this.setState({ communityCards: newCommunityCards });
  };
}
