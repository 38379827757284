import { Card } from "../cards/Cards";
import { cx } from "../util/Utils";
import styles from "./CardView.module.css";

export interface Props {
  card: Card | "blank";
  className?: string;
}

export default function CardView(props: Props) {
  return (
    <img
      className={cx(styles.root, props.className)}
      alt={props.card}
      src={`/cards/${props.card}.svg`}
    />
  );
}
