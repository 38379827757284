import { Card } from "../cards/Cards";
import CardView from "./CardView";
import styles from "./CardRowView.module.css";
import React from "react";

export interface Props {
  cards: readonly Card[];
  numCards: number;
}

function BlankCard() {
  return <CardView className={styles.blankCard} card="blank" />;
}

export default function CardRowView(props: Props) {
  const cardElements: React.ReactNode[] = [];
  for (let i = 0; i < props.numCards; ++i) {
    const card: Card | undefined = props.cards[i];
    cardElements.push(
      <span key={i} className={styles.cardContainer}>
        {card === undefined ? <BlankCard /> : <CardView card={card} />}
      </span>
    );
  }
  return <div className={styles.root}>{cardElements}</div>;
}
