import { Channel, PresenceChannel } from "pusher-js";
import React from "react";
import styles from "./App.module.css";
import { Card } from "./cards/Cards";
import DealerView from "./DealerView";
import { cardGapSize, determineCardSize } from "./helpers/SizeHelpers";
import {
  parseQueryParameters,
  QueryParameterFields
} from "./helpers/QueryParameterHelpers";
import PlayerView from "./PlayerView";
import { getOrCreatePusher } from "./pusher/PusherClient";
import PageFooter from "./ui/PageFooter";

const CHANNEL_NAME = "presence-test-channel";
const NEW_HAND_EVENT_NAME = "client-newHand";

function isPresenceChannel(channel: Channel): channel is PresenceChannel {
  return "members" in channel;
}

type State = {
  queryFields: QueryParameterFields;
  handId?: string;
  numDealers?: number;
  numPlayers?: number;
  cardHeight: string;
  cardWidth: string;
};

export default class App extends React.Component<{}, State> {
  public state: State = (() => {
    const queryFields = parseQueryParameters();
    return { queryFields, ...determineCardSize(queryFields.mode) };
  })();

  private readonly pusher = getOrCreatePusher(this.state.queryFields.mode);
  private readonly channel = (() => {
    const channel = this.pusher.subscribe(CHANNEL_NAME);
    if (!isPresenceChannel(channel)) {
      throw new Error("expected presence channel");
    }
    channel.bind_global((event: string, data: any) =>
      this.onChannelEvent(event, data)
    );

    return channel;
  })();
  private readonly playerViewRef = React.createRef<PlayerView>();

  public render() {
    const { mode, gameType } = this.state.queryFields;
    const { numDealers, numPlayers, handId } = this.state;

    return (
      <div
        className={styles.root}
        style={
          {
            "--card-height": this.state.cardHeight,
            "--card-width": this.state.cardWidth,
            "--card-gap": cardGapSize
          } as any
        }
      >
        <div className={styles.mainContent}>
          {mode === "dealer"
            ? this.renderDealerBody()
            : this.renderPlayerBody()}
        </div>
        <div className={styles.footerContent}>
          <PageFooter {...{ mode, gameType, numDealers, numPlayers, handId }} />
        </div>
      </div>
    );
  }

  private renderDealerBody() {
    return (
      <DealerView
        getPlayerNames={this.getPlayerNames}
        sendNewHandData={this.sendNewHandData}
        gameType={this.state.queryFields.gameType}
      />
    );
  }

  private renderPlayerBody() {
    return <PlayerView ref={this.playerViewRef} />;
  }

  public componentDidMount() {
    super.componentDidMount?.();
    window.addEventListener("resize", this.onWindowResize);
    this.onWindowResize();
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
    this.channel.unbind_all();
    super.componentWillUnmount?.();
  }

  private readonly onChannelEvent = (event: string, data: any) => {
    console.log("got channel event", event, data);
    switch (event) {
      case "pusher:member_added":
      case "pusher:member_removed":
      case "pusher:subscription_succeeded":
        this.updateCounts();
        break;

      case NEW_HAND_EVENT_NAME:
        this.handleNewHand(data);
        break;
    }
  };

  private readonly updateCounts = () => {
    const { members } = this.channel.members;
    let numDealers = 0;
    let numPlayers = 0;
    Object.keys(members).forEach(memberName => {
      if (memberName.startsWith("dealer")) {
        numDealers++;
      }
      if (memberName.startsWith("player")) {
        numPlayers++;
      }
    });
    this.setState({ numDealers, numPlayers });
  };

  private readonly getPlayerNames = () => {
    return Object.keys(this.channel.members.members).filter(n =>
      n.startsWith("player")
    );
  };

  private readonly sendNewHandData = (
    handId: string,
    playersAndCards: Record<string, Card[]>
  ) => {
    this.channel.trigger(NEW_HAND_EVENT_NAME, { handId, playersAndCards });
    this.setState({ handId });
  };

  private readonly handleNewHand = (data: {
    handId: string;
    playersAndCards: Record<string, [Card, Card]>;
  }) => {
    const playerView = this.playerViewRef.current;
    if (playerView === null) {
      // no player view, so no-op
      return;
    }

    const myPlayer = this.channel.members.me.id;
    const myCards = data.playersAndCards[myPlayer];
    let handId: string | undefined;
    if (myCards === undefined) {
      playerView?.reset();
      handId = undefined;
    } else {
      playerView?.startNewHand(data.handId, myCards);
      handId = data.handId;
    }

    this.setState({ handId });
  };

  private readonly onWindowResize = () => {
    this.setState(determineCardSize(this.state.queryFields.mode));
  };
}
