import { memoize } from "lodash";
import { readonlyRecordOf } from "./util/Utils";

interface PartialGameTypeConfig {
  label?: string;
  numHoleCards: number;
}

type GameTypeConfig = Required<PartialGameTypeConfig>;

const configs = readonlyRecordOf<PartialGameTypeConfig>()({
  holdem: {
    numHoleCards: 2
  },
  omaha: {
    numHoleCards: 4
  }
});

export type GameType = keyof typeof configs;

export const gameTypes: readonly GameType[] = Object.keys(
  configs
) as GameType[];

export const getGameTypeConfig = memoize(
  (gameType: GameType): GameTypeConfig => {
    const config = configs[gameType];
    return {
      ...config,
      label: config.label ?? gameType
    };
  }
);
