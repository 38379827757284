import { allCards, Card } from "./Cards";

export default class Deck {
  private readonly mutableCards: Card[];
  public readonly cards: ReadonlyArray<Card>;

  public constructor(cards: ReadonlyArray<Card>) {
    this.mutableCards = [...cards];
    this.cards = this.mutableCards;
  }

  public static createFull() {
    return new Deck(allCards);
  }

  public static createEmpty() {
    return new Deck([]);
  }

  public toJSON() {
    return { cards: this.cards };
  }

  public toString() {
    return JSON.stringify(this.cards);
  }

  public get size() {
    return this.cards.length;
  }

  public tryRemoveAt(index: number): Card | undefined {
    const prevSize = this.cards.length;
    if (index >= 0 && index < prevSize) {
      const card = this.mutableCards[index];
      this.mutableCards[index] = this.cards[prevSize - 1];
      this.mutableCards.pop();
      return card;
    } else {
      return undefined;
    }
  }

  public tryRemoveCardSlow(card: Card): boolean {
    const index = this.indexOfCardSlow(card);
    if (index !== -1) {
      this.tryRemoveAt(index);
      return true;
    } else {
      return false;
    }
  }

  public indexOfCardSlow(card: Card): number {
    return this.cards.indexOf(card);
  }

  public removeAt(index: number): Card {
    const card = this.tryRemoveAt(index);
    if (card === undefined) {
      throw new Error(`no card at index: ${index}`);
    }
    return card;
  }

  public draw(): Card {
    return this.removeAt(0);
  }

  public removeCardSlow(card: Card) {
    if (!this.tryRemoveCardSlow(card)) {
      throw new Error(`card not in deck: ${card}`);
    }
  }

  // adapted from https://stackoverflow.com/a/2450976
  public shuffle() {
    const array = this.mutableCards;

    let currentIndex = array.length;
    let randomIndex: number;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex]
      ];
    }

    return array;
  }

  public hasSlow(card: Card): boolean {
    return this.cards.includes(card);
  }
}
