import { GameType, getGameTypeConfig } from "../GameType";
import {
  encodeQueryParameters,
  Mode,
  parseQueryParameters
} from "../helpers/QueryParameterHelpers";
import styles from "./PageFooter.module.css";

export interface Props {
  mode: Mode;
  gameType: GameType;
  numDealers?: number;
  numPlayers?: number;
  handId?: string;
}

export default function PageFooter(props: Props) {
  return (
    <div className={styles.root}>
      <div>
        Mode: <strong>{props.mode}</strong> (
        <a href={generateSwitchModeHref()}>switch</a>)
      </div>
      {props.mode === "player" ? null : (
        <div>
          Game: <strong>{getGameTypeConfig(props.gameType).label}</strong> (
          <a href={generateSwitchGameTypeHref()}>switch</a>)
        </div>
      )}
      <div>
        connected: <strong>{props.numDealers}</strong>d/
        <strong>{props.numPlayers}</strong>p
      </div>
      <div>
        Hand ID: <strong>{props.handId}</strong>
      </div>
    </div>
  );
}

function generateSwitchModeHref() {
  const p = parseQueryParameters();
  p.mode = p.mode === "player" ? "dealer" : "player";
  return encodeQueryParameters(p);
}

function generateSwitchGameTypeHref() {
  const p = parseQueryParameters();
  p.gameType = p.gameType === "holdem" ? "omaha" : "holdem";
  return encodeQueryParameters(p);
}
