import React from "react";
import { cx } from "../util/Utils";
import styles from "./Button.module.css";

export interface Props {
  onClick?: () => void;
  children?: React.ReactNode;
  large?: boolean;
}

export default class Button extends React.Component<Props> {
  public render() {
    return (
      <div
        className={cx(
          styles.button,
          this.props.large ? styles.large : undefined
        )}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </div>
    );
  }
}
