import { createIndexDictionaryAssumeFull } from "../util/Utils";

export type Rank =
  | "A"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "T"
  | "J"
  | "Q"
  | "K";

export type Suit = "c" | "d" | "h" | "s";

export type Card = `${Rank}${Suit}`;

export const allRanks: ReadonlyArray<Rank> = [
  "A",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "T",
  "J",
  "Q",
  "K"
];

const rankToIndex = createIndexDictionaryAssumeFull(allRanks);
export const ACE_RANK_INDEX = rankToIndex["A"];
export const KING_RANK_INDEX = rankToIndex["K"];
export const TWO_RANK_INDEX = rankToIndex["2"];

export const allSuits: ReadonlyArray<Suit> = ["s", "c", "h", "d"];

export const allCards: ReadonlyArray<Card> = (() => {
  const cards: Card[] = [];
  for (const suit of allSuits) {
    for (const rank of allRanks) {
      cards.push(`${rank}${suit}`);
    }
  }
  return cards;
})();

export function isRank(value: string): value is Rank {
  return allRanks.includes(value as any);
}

export function isSuit(value: string): value is Suit {
  return allSuits.includes(value as any);
}

export function isCard(value: string): value is Card {
  return value.length === 2 && isRank(value[0]) && isSuit(value[1]);
}

export function rankOf(card: Card): Rank {
  return card[0] as Rank;
}

export function suitOf(card: Card): Suit {
  return card[1] as Suit;
}

export function rankIndexOf(rank: Rank): number {
  return rankToIndex[rank];
}
