import { GameType, gameTypes } from "../GameType";

export type Mode = "dealer" | "player";

export interface QueryParameterFields {
  mode: Mode;
  gameType: GameType;
}

type Parser<T> = {
  decode: (value: string | null) => T;
  encode: (value: T, allValues: QueryParameterFields) => string | null;
};

const queryParameterParsers: {
  [k in keyof QueryParameterFields]: Parser<QueryParameterFields[k]>;
} = {
  mode: {
    decode: value => (value === "dealer" ? "dealer" : "player"),
    encode: value => value
  },
  gameType: {
    decode: value =>
      gameTypes.includes(value as any) ? (value as GameType) : "holdem",
    encode: (value, allValues) => (allValues.mode === "dealer" ? value : null)
  }
};

export function parseQueryParameters(): QueryParameterFields {
  const p = new URLSearchParams(window.location.search);

  const result: Partial<QueryParameterFields> = {};

  Object.entries(queryParameterParsers).forEach(([key, parser]) => {
    (result as any)[key] = parser.decode(p.get(key));
  });

  return result as QueryParameterFields;
}

export function encodeQueryParameters(fields: QueryParameterFields) {
  const result = new URLSearchParams();
  Object.entries(queryParameterParsers).forEach(([key, parser]) => {
    const value = (parser as Parser<any>).encode(
      fields[key as keyof QueryParameterFields],
      fields
    );
    if (value !== null) {
      result.set(key, value);
    }
  });
  let resultStr = result.toString();
  if (!resultStr.startsWith("?")) {
    resultStr = `?${resultStr}`;
  }
  return resultStr;
}
